<template>
	<div class="w-full mt-20 relative">
		<div class="flex justify-between items-center">
			<h2 class="font-extrabold sm:text-xl">Для санаторно-курортных организаций и психиатрических больниц</h2>
			<div class="flex items-center">
				<Button
					v-if="!decisionBlock.flag && userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])"
					text="Отправить данные и закрыть редактирование"
					@click.native="blockUnBlockDecision()"
					class="mr-2"
				/>
				<Button v-else-if="decisionBlock.flag && userIsInRoles(['Support', 'MZ_Employee'])" text="Разблокировать" @click.native="blockUnBlockDecision()" class="mr-2" />
				<Button text="Экспорт в Excel" icon="exel" @click.native="downloadExcel(params)" :disabled="loadingExcel" :load="loadingExcel" />
			</div>
		</div>
		<div class="table-respons">
			<table>
				<thead>
					<tr>
						<th rowspan="3" class="th-sort">
							<p @click="switchFilterVisibility(0)">
								<span class="cursor-pointer underline"> Наименование МО </span>
							</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="0" :param="params" @query="getData" :fields="fields" :item="fields[0]" />
						</th>
						<th rowspan="3" class="th-sort">
							<p @click="switchFilterVisibility(1)">
								<span class="cursor-pointer underline"> № протокола </span>
							</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="1" :param="params" @query="getData" :fields="fields" :item="fields[1]" />
						</th>
						<th rowspan="3" class="th-sort">
							<p @click="switchFilterVisibility(2)">
								<span class="cursor-pointer underline"> Дата протокола </span>
							</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="2" :param="params" @query="getData" :fields="fields" :item="fields[2]" />
						</th>
						<th colspan="5">
							Показатели, характеризующие общие критерии оценки качества условий оказания услуг медицинскими организациями, в отношении которых проводится независимая оценка
						</th>
					</tr>
					<tr>
						<th colspan="2">Открытость и доступность информации об организации</th>
						<th>Комфортность условий предоставления услуг, включая время ожидания предоставления медицинской услуги</th>
						<th colspan="2">Доступность услуг для инвалидов</th>
					</tr>
					<tr>
						<th>
							<p @click="switchFilterVisibility(3)">
								<span class="cursor-pointer underline">1.1</span>
							</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="3" :param="params" @query="getData" :fields="fields" :item="fields[3]" />
						</th>
						<th>
							<p @click="switchFilterVisibility(4)">
								<span class="cursor-pointer underline">1.2</span>
							</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="4" :param="params" @query="getData" :fields="fields" :item="fields[4]" />
						</th>
						<th>
							<p @click="switchFilterVisibility(5)">
								<span class="cursor-pointer underline">3.1</span>
							</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="5" :param="params" @query="getData" :fields="fields" :item="fields[5]" />
						</th>
						<th>
							<p @click="switchFilterVisibility(6)">
								<span class="cursor-pointer underline">3.2</span>
							</p>
							<FilterComponent v-on:change="switchFilterVisibility(null)" :filterOpen="filterOpen" :id-filter="6" :param="params" @query="getData" :fields="fields" :item="fields[6]" />
						</th>
					</tr>
				</thead>
				<tbody v-for="(item, index) in decsisionSP" :key="index">
					<tr :class="{ 'bg-gray-30': item.id === opened, 'cursor-pointer': item.hasBranch }" @click="openRow(item)">
						<td>
							<div class="flex" v-if="!item.update">
								<Icons
									v-if="!decisionBlock.flag && userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])"
									class="mr-2 icon-control cursor-pointer"
									icon-name="pen"
									@click.native="openUpdate(item)"
								/>
								<p :class="{ underline: item.hasBranch }">{{ item.medicalOrgName }}</p>
							</div>
							<div class="flex" v-else>
								<Icons class="mr icon-control cursor-pointer" icon-name="square-ok" @click.native="save(item)" />
								<Icons class="mr-2 icon-control cursor-pointer" icon-name="square-close" @click.native="closeUpdate(item, true)" />
								<p>{{ item.medicalOrgName }}</p>
							</div>
						</td>
						<td>
							<div v-if="!item.update">
								<p>{{ item.protokolNumber }}</p>
							</div>
							<div v-else>
								<TextInput type="number" v-model="item.protokolNumber" />
							</div>
						</td>
						<td>
							<div v-if="!item.update">
								<p>{{ item.protokolDate === '---' ? '---' : item.protokolDate | moment('DD.MM.YYYY') }}</p>
							</div>
							<div v-else>
								<TextInput type="date" :min="minProtocolDate" :max="currentDate" v-model="item.protokolDate" :error="errorProtocolDate" />
							</div>
						</td>
						<td>
							<div v-if="!item.update">
								<p>{{ item.value1_1 }}</p>
							</div>
							<div v-else>
								<TextInput @input="clearNumbers(item, 'value1_1')" min="0" max="100" step="1" type="number" v-model="item.value1_1" />
							</div>
						</td>
						<td>
							<div v-if="!item.update">
								<p>{{ item.value1_2 }}</p>
							</div>
							<div v-else>
								<TextInput @input="clearNumbers(item, 'value1_2')" min="0" max="100" step="1" type="number" v-model="item.value1_2" />
							</div>
						</td>
						<td class="text-center">
							<div v-if="!item.update">
								<p>{{ item.value2_2 }}</p>
							</div>
							<div v-else>
								<TextInput @input="clearNumbers(item, 'value2_2')" min="0" max="100" step="1" type="number" v-model="item.value2_2" />
							</div>
						</td>
						<td>
							<div v-if="!item.update">
								<p>{{ item.value3_1 }}</p>
							</div>
							<div v-else>
								<TextInput @input="clearNumbers(item, 'value3_1')" min="0" max="100" step="1" type="number" v-model="item.value3_1" />
							</div>
						</td>
						<td>
							<div v-if="!item.update">
								<p>{{ item.value3_2 }}</p>
							</div>
							<div v-else>
								<TextInput @input="clearNumbers(item, 'value3_2')" min="0" max="100" step="1" type="number" v-model="item.value3_2" />
							</div>
						</td>
					</tr>
					<template v-if="item.id === opened">
						<tr v-for="subItem in item.branches" :key="subItem.id" :class="{ 'bg-gray-30': item.id === opened }">
							<td>
								<div class="flex items-center">
									<div v-if="subItem.isBranch" class="default-filial-clone">Филиал</div>
									<div v-if="subItem.isClone" class="default-filial-clone ml-2">Вторичная МО</div>
								</div>
								<div class="flex" v-if="!subItem.update">
									<Icons
										v-if="!decisionBlock.flag && userIsInRoles(['Support', 'MZ_Employee', 'OGV_Curator'])"
										class="mr-2 icon-control cursor-pointer"
										icon-name="pen"
										@click.native="openUpdate(item)"
									/>
									<p>{{ subItem.medicalOrgName }}</p>
								</div>
								<div class="flex" v-else>
									<Icons class="mr icon-control cursor-pointer" icon-name="square-ok" @click.native="save(item)" />
									<Icons class="mr-2 icon-control cursor-pointer" icon-name="square-close" @click.native="closeUpdate(item, true)" />
									<p>{{ subItem.medicalOrgName }}</p>
								</div>
							</td>
							<td>
								<div v-if="!subItem.update">
									<p>{{ subItem.protokolNumber }}</p>
								</div>
								<div v-else>
									<TextInput type="number" :error="protocol" v-model="subItem.protokolNumber" />
								</div>
							</td>
							<td>
								<div v-if="!subItem.update">
									<p>{{ subItem.protokolDate === '---' ? '---' : subItem.protokolDate | moment('DD.MM.YYYY') }}</p>
								</div>
								<div v-else>
									<TextInput type="date" :min="minProtocolDate" :max="currentDate" v-model="subItem.protokolDate" :error="errorProtocolDate" />
								</div>
							</td>
							<td>
								<div v-if="!subItem.update">
									<p>{{ subItem.value1_1 }}</p>
								</div>
								<div v-else>
									<TextInput @input="clearNumbers(item, 'value1_1')" type="number" min="0" max="100" step="1" v-model.number="subItem.value1_1" />
								</div>
							</td>
							<td>
								<div v-if="!subItem.update">
									<p>{{ subItem.value1_2 }}</p>
								</div>
								<div v-else>
									<TextInput @input="clearNumbers(item, 'value1_2')" type="number" min="0" max="100" step="1" v-model.number="subItem.value1_2" />
								</div>
							</td>
							<td class="text-center">
								<div v-if="!subItem.update">
									<p>{{ subItem.value2_2 }}</p>
								</div>
								<div v-else>
									<TextInput @input="clearNumbers(item, 'value2_2')" min="0" max="100" step="1" type="number" v-model="item.value2_2" />
								</div>
							</td>
							<td>
								<div v-if="!subItem.update">
									<p>{{ subItem.value3_1 }}</p>
								</div>
								<div v-else>
									<TextInput @input="clearNumbers(item, 'value3_1')" type="number" min="0" max="100" step="1" v-model.number="subItem.value3_1" />
								</div>
							</td>
							<td>
								<div v-if="!subItem.update">
									<p>{{ subItem.value3_2 }}</p>
								</div>
								<div v-else>
									<TextInput @input="clearNumbers(item, 'value3_2')" type="number" min="0" max="100" step="1" v-model.number="subItem.value3_2" />
								</div>
							</td>
						</tr>
					</template>
				</tbody>
			</table>

			<div v-if="loadingTable" class="absolute w-full h-full preloader rounded-lg">
				<loadingComponent class="absolute top-1/2 left-1/2" />
			</div>
			<center v-if="!loadingTable && decsisionSP.length === 0" class="w-full py-28 whitespace-no-wrap text-xl leading-5 text-gray-500 text-center">Данных нет...</center>
		</div>
		<Pagination :page="page" :totalCount="totalCount" :params="params" @query="getData" />
	</div>
</template>

<script>
import Button from '@/components/Button.vue';
import Icons from '@/components/Icons.vue';
import TextInput from '@/components/TextInput.vue';
import FilterComponent from '@/components/Filter.vue';
import Pagination from '@/components/Pagination.vue';
// Глобальные данные стора
import { getters, mutations, methods, actions } from '@/store/store';
// Локальные данные стора
import actionsLocal from '../store/actions';
import loadingComponent from '@/components/Loading.vue';

export default {
	name: 'DecisionsPublicCouncil',
	components: {
		Button,
		loadingComponent,
		TextInput,
		Icons,
		FilterComponent,
		Pagination
	},
	data() {
		return {
			currentDate: '',
			minProtocolDate: '',
			rowsVisible: 5,
			totalCount: 0,
			openDelet: false,
			open: false,
			filterOpen: null,
			filterBy: null,
			decisionBlock: {
				type: 2,
				year: 2022,
				flag: false
			},
			popupTitle: '',
			params: {
				filters: [
					{
						fieldName: 'RegionId',
						filterType: 1,
						filterValueType: 1,
						value1: ''
					},
					{
						fieldName: 'isMain',
						filterType: 1,
						filterValueType: 1,
						value1: 'true'
					}
				],
				sortOption: {
					fieldName: '',
					sortType: null
				},
				pageSize: 5,
				currentPage: 0,
				columns: []
			},
			page: 0,
			fields: [
				{
					Name: 'Наименование МО',
					fieldName: 'medicalOrgName',
					filterType: 2,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '№ протокола',
					fieldName: 'protokolNumber',
					filterType: 2,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: 'Дата протокола',
					fieldName: 'protokolDate',
					filterType: 10,
					filterValueType: 1,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '1.1',
					fieldName: 'value1_1',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '1.2',
					fieldName: 'value1_2',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '2.2',
					fieldName: 'value2_2',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '3.1',
					fieldName: 'value3_1',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				},
				{
					Name: '3.2',
					fieldName: 'value3_2',
					filterType: 1,
					filterValueType: 2,
					value1: '',
					value2: null,
					sortBy: null,
					label1: 'По возрастанию (А-Я)',
					label2: 'По убыванию (Я-А)'
				}
			],
			decsisionSP: [],
			openedItem: null,
			savedItem: {},
			loadingExcel: false,
			loadingTable: false,
			errorProtocolDate: null,
			opened: null
		};
	},
	computed: {
		...getters
	},
	methods: {
		...mutations,
		...actions,
		...actionsLocal,
		...methods,
		openRow(item) {
			if (item.hasBranch) {
				if (this.opened === item.id) {
					this.opened = null;
				} else {
					this.opened = item.id;
				}
			}
		},
		clearNumbers(item, param) {
			if (Number.isInteger(item[param])) {
				item[param] = 0;
			} else if (item[param] >= 100) {
				item[param] = 100;
			} else if (item[param] <= 0) {
				item[param] = 0;
			}
		},
		blockUnBlockDecision() {
			let message = this.decisionBlock.flag ? 'Вы уверены, что хотите открыть редактирование?' : 'Вы уверены, что хотите закрыть редактирование?';

			if (confirm(message)) {
				this.decisionBlock.flag = !this.decisionBlock.flag;
				this.blockUnBlockFullDecision(this.decisionBlock);
			}
		},
		openUpdate(item) {
			if (this.openedItem !== null) {
				this.closeUpdate(this.openedItem, true);
			}
			this.openedItem = item;
			Object.assign(this.savedItem, item);
			this.$set(item, 'update', true);
		},
		closeUpdate(item, restore) {
			if (restore && item.medicalOrgId === this.savedItem.medicalOrgId) {
				Object.assign(item, this.savedItem);
			}
			this.$set(item, 'update', false);
			this.openedItem = null;
			this.savedItem = {};
		},
		save(item) {
			if (!this.protocolDateIsValid(item)) {
				this.errorProtocolDate = 'Неверная дата';
			} else {
				this.updateOSDecsisionSP(item).then(() => {
					this.closeUpdate(item, false);
					this.errorProtocolDate = null;
				});
			}
		},
		protocolDateIsValid(item) {
			let protocolDate = this.$moment(item.protokolDate, 'YYYY-MM-DD');

			if (this.$moment(this.minProtocolDate, 'YYYY-MM-DD') <= protocolDate && protocolDate <= this.$moment(this.currentDate, 'YYYY-MM-DD')) {
				return true;
			}

			return false;
		},
		downloadExcel(params) {
			this.loadingExcel = true;
			let regionId = this.getRegionId();
			this.$http
				.post(`OS/GetOSDecsisionSPExcel?regionId=${regionId}`, params, {
					responseType: 'blob',
					headers: {
						'Access-Control-Expose-Headers': 'Content-Disposition'
					}
				})
				.then((res) => {
					let fileNameAndType = decodeURI(`${this.regionName}_Санаторно-курортных организаций и психиатрических больниц.xlsx`);

					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileNameAndType);
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					this.loadingExcel = false;
				});
		},
		switchFilterVisibility(key) {
			this.filterOpen = this.filterOpen === key ? null : key;
			this.filterBy = key;
		},
		getData(params) {
			this.loadingTable = true;
			let regionId = this.getRegionId();

			this.getOSDecsisionSP(params, regionId).then((res) => {
				res.data.data.map((item) => {
					this.initializeItem(item);
					item.branches.forEach((x) => this.initializeItem(x));
					return item;
				});
				this.decsisionSP = res.data.data;
				this.totalCount = Math.ceil(res.data.totalRecords / this.params.pageSize);
				this.openedItem = null;
				this.savedItem = {};
				this.loadingTable = false;
			});
		},
		initializeItem(item) {
			item.update = false;
			item.protokolDate = item.protokolDate ? this.$moment(item.protokolDate).format('YYYY-MM-DD') : '---';
		}
	},
	mounted() {
		this.currentDate = this.$moment().format('YYYY-MM-DD');
		this.minProtocolDate = this.$moment().subtract(3, 'months').format('YYYY-MM-DD');

		let regionId = this.getRegionId();
		this.params.filters[0].value1 = regionId;
		this.decisionBlock.regionId = regionId;

		this.getDecisionBlockStatus(this.decisionBlock).then((res) => {
			this.decisionBlock.flag = res.data.settingValue === 'true';
		});

		this.getRegionYearData(regionId).then(() => {
			this.params.filters.push({
				fieldName: 'YearStr',
				filterType: 1,
				filterValueType: 1,
				value1: this.getYears.currentYear.toString(),
				value2: ''
			});
			this.getData(this.params);
		});
	}
};
</script>

<style scoped>
.table-respons td,
.table-respons th {
	padding: 20px 30px;
	vertical-align: center;
	text-align: left;
	border-bottom: 1px solid #f2f5ff;
	border-right: 1px solid #f2f5ff;
	position: relative;
}

.table-respons th.text-center,
.table-respons td.text-center {
	text-align: center;
}

th {
	font-weight: 600;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

td {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #6a6e83;
}

.table-respons {
	min-height: 385px;
	position: relative;
	overflow: auto;
	width: 100%;
	margin-top: 20px;
}

.table-respons .th-sort {
	/* text-decoration-line: underline; */
	/* cursor: pointer; */
}

.bold td {
	font-weight: 600;
}

.table-respons {
	background: #fff;
	border-radius: 10px 10px 0 0;
	box-shadow: 0px 4px 17px rgba(171, 184, 225, 0.4);
}

.table-respons::-webkit-scrollbar {
	width: 11px; /* ширина всей полосы прокрутки */
}

.table-respons::-webkit-scrollbar-track {
	background: rgba(168, 177, 206, 0.3); /* цвет зоны отслеживания */
}

.table-respons::-webkit-scrollbar-thumb {
	background: #3377ff;
	border-radius: 10px;
}

.default-filial-clone {
	padding: 4px 8px;
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	background: #a8b1ce;
	border-radius: 4px;
	width: 110px;
	color: #fff;
	text-align: center;
}
</style>
